import React from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { NavLinks } from '@/components/composed/Header/components'
import { Image, Logo } from '@/components/ui'
import { messages } from './messages'
import './styles.scss'

const Error404Page = () => {
  return (
    <>
      <section className="page-404">
        <Logo url="/" color={EColor.BLACK} className="page-404__logo" />

        <h2 className="page-404__title">{messages.title}</h2>

        <NavLinks classname="page-404__nav-list" />
      </section>

      <div className="page-404-image-wrapper">
        <Image isInStatic src="errors/404.png" />
      </div>
    </>
  )
}

export default Error404Page
